body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CUSTOM DATE PICKER CSS  */
input.datepicker {
  margin-right: 10px; 
  border: 1px solid #273E2A;
  width: 100px;
  height: 40px; 
  padding: 10px;
  background-color: #273E2A;
  text-align: center;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}
input.datepicker::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}
